
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiInspection } from "@/core/api";
import {
  formatDateTime,
  modalShowListener,
} from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";
import MismatchedPimSku from "./MismatchedPimSku.vue";
import RetourToMerchantQtyModal from "./RetourToMerchantQtyModal.vue";

export default defineComponent({
  name: "warehouse-management-inspection-mismatched-sku-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    scanNumber: {
      type: String,
      default: "",
    },
    checkedDate: {
      type: String,
      default: "",
    },
    checkedUser: {
      type: String,
      default: "",
    },
  },
  components: {
    ProductInfo,
    SkuInfo,
    MismatchedPimSku,
    RetourToMerchantQtyModal,
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const mismatchedSkuRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      items: [] as any[],
      mismatched: [] as any[],
      products: [] as any[],
      product_id: "",
      radio: "",
    });

    const rules = ref({
      // radio: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const options = ref({});

    const userInfo = ref<User>(store.getters.currentUser);

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (formData.value.radio === "" && formData.value.product_id === "") {
        flag = true;
      }
      return flag;
    });

    const isShowRetourToMerchant = computed(() => {
      let flag = true;
      // if (props.scanNumber === "") {
      //   flag = false;
      // }
      return flag;
    });

    const removeProductItem = (index: number) => {
      formData.value.products.splice(index, 1);
      formData.value.product_id = "";
    };

    const updateSku = (product) => {
      formData.value.radio = "";
      formData.value.products = [product];
      formData.value.product_id = product.id;
    };

    const getMaxQty = computed(() => {
      const product = getProduct.value;
      if (product) {
        return product.qty;
      } else {
        return 1;
      }
    });

    const getShowInfo = async () => {
      const { data } = await ApiInspection.getInspectionItems({
        id: route.params.id,
      });
      if (data.code == 0) {
        // const arr: any[] = data.data.missing.filter(
        //   (item) => item.inspection_result === 0
        // );
        formData.value.items = data.data.article;
        formData.value.mismatched = data.data.mismatched;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          if (isExceededQty.value) {
            const product = getRadioProduct.value;
            Swal.fire({
              icon: "warning",
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: t("common.okGotIt"),
              cancelButtonText: t("common.nopeCancelIt"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
              html: t("inspection.warningExceededQty", [
                getMaxQty.value +
                  product.received_quantity -
                  product.demand_quantity,
              ]),
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                submitRadio();
              }
            });
          } else {
            submitRadio();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const isExceededQty = computed(() => {
      let flag = false;
      if (formData.value.radio) {
        const product = getRadioProduct.value;
        if (
          product.received_quantity + getMaxQty.value >
          product.demand_quantity
        ) {
          flag = true;
        }
      }
      return flag;
    });

    const getRadioProduct = computed(() => {
      const product = formData.value.items.find(
        (item) => item.sku_identifier === formData.value.radio
      );
      return product;
    });

    const getProduct = computed(() => {
      if (props.id) {
        const product = formData.value.mismatched.find(
          (item) => item.sku_identifier === props.id
        );
        if (product) {
          return product;
        } else {
          return null;
        }
      } else if (props.scanNumber) {
        const product = formData.value.mismatched.find(
          (item) => item.scan_number === props.scanNumber
        );
        if (product) {
          return product;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });

    const submitRadio = async () => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiInspection.updateInspectionMismatched({
        id: route.params.id,
        scan_number: props.scanNumber,
        sku_id: formData.value.product_id || formData.value.radio,
        origin_sku_id: props.id,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          emit("update-list");
          hideModal(mismatchedSkuRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const submitFailed = async () => {
      Swal.fire({
        title: "Are you sure mark the SKU matching failed?",
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          // loading.value = true;
          // setSubmitAttribute();
          // const { data } = await ApiInspection.updateInspectionMismatched({
          //   id: route.params.id,
          //   stock_in_id: props.id,
          //   scan_number: props.scanNumber,
          // });
          // loading.value = false;
          // setSubmitAttribute(false);
          // if (data.code == 0) {
          //   showFormSubmitSuccessMsg(() => {
          //     emit("update-list");
          //     hideModal(mismatchedSkuRef.value);
          //   });
          // } else {
          //   showServerErrorMsg(data);
          // }
        }
      });
    };

    const qtySubmit = () => {
      emit("update-list");
      hideModal(mismatchedSkuRef.value);
    };

    const handleDiscard = () => {
      hideModal(mismatchedSkuRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    const customFormatDateTime = (date, format = "YYYY-MM-DD HH:mm") => {
      return formatDateTime(date, format);
    };

    onMounted(() => {
      modalShowListener(mismatchedSkuRef.value, () => {
        init();
      });
      modalHideListener(mismatchedSkuRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formatDateTime,
      formRef,
      submitButtonRef,
      submitButton,
      mismatchedSkuRef,
      props,
      loading,
      formData,
      rules,
      options,
      userInfo,
      isDisabledSubmit,
      isShowRetourToMerchant,
      removeProductItem,
      updateSku,
      getMaxQty,
      qtySubmit,
      handleDiscard,
      resetForm,
      submit,
      submitFailed,
      customFormatDateTime,
    };
  },
});
