
import { computed, defineComponent, ref, watch } from "vue";
import { CommonHtmlType } from "@/core/directive/type/common";

export default defineComponent({
  name: "warehouse-management-inspection-edit-double-sale-article-guid-modal",
  props: {
    guids: {
      type: Array,
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, context) {
    const loading = ref<boolean>(false);
    const updateDoubleSaleArticleRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      items: [] as any[],
    });

    const rules = ref({});

    const options = ref({});

    watch(
      () => props.guids,
      (newValue) => {
        if (newValue) {
          formData.value.items = newValue;
        }
      }
    );

    const changeGuid = (item) => {
      item.isflag = !item.isflag;
    };

    const getUpdateGuids = computed(() => {
      return formData.value.items.map((item) => {
        if (!item.isflag) {
          return item.guid;
        }
      });
    });

    const handleDiscard = () => {
      context.emit("reset-form");
    };

    const submit = () => {
      context.emit("update-list", getUpdateGuids.value);
    };

    return {
      formRef,
      updateDoubleSaleArticleRef,
      props,
      loading,
      formData,
      rules,
      options,
      changeGuid,
      handleDiscard,
      submit,
    };
  },
});
