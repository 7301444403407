
import { defineComponent, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { modalShowListener } from "@/core/directive/function/common";
import { ApiInspection } from "@/core/api";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "warehouse-management-inspection-retour-to-merchant-qty-change-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    maxQty: {
      type: Number,
      default: 1,
    },
    scanNumber: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const retourToMerchantQty = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonHtmlType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      quantity: 1,
    });

    const rules = ref({});

    const options = ref({});

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          updateInspectionMismatchedToRetrunMerchant();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateInspectionMismatchedToRetrunMerchant = async () => {
      loading.value = true;
      const { data } =
        await ApiInspection.updateInspectionMismatchedToRetrunMerchant({
          id: route.params.id,
          sku_identifier: props.id,
          scan_number: props.scanNumber,
          quantity: formData.value.quantity,
        });
      loading.value = false;
      if (data.code === 0) {
        emit("update-list");
        hideModal(retourToMerchantQty.value);
      } else {
        showServerErrorMsg(data);
      }
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      modalShowListener(retourToMerchantQty.value, () => {
        setTimeout(() => {
          formData.value.quantity = props.maxQty;
        }, 0);
      });
      modalHideListener(retourToMerchantQty.value, () => {
        resetForm();
      });
    });

    return {
      t,
      props,
      retourToMerchantQty,
      loading,
      formData,
      submitButton,
      formRef,
      options,
      rules,
      submit,
      resetForm,
    };
  },
});
