import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bbc44a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-body p-9" }
const _hoisted_5 = { class: "guids row" }
const _hoisted_6 = { class: "guid-value" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "card-footer d-flex justify-content-end p-5" }
const _hoisted_9 = { class: "svg-icon svg-icon-3" }
const _hoisted_10 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("inspection.goodsItems")), 1)
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-md-4",
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["guid-box d-flex align-items-center justify-content-between", {
              default: item.isflag,
              remove: !item.isflag,
            }])
            }, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(item.guid), 1),
              _createElementVNode("span", {
                class: "svg-icon svg-icon-3 m-0 cursor-pointer",
                onClick: ($event: any) => (_ctx.changeGuid(item))
              }, [
                _withDirectives(_createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen042.svg" }, null, 512), [
                  [_vShow, item.isflag]
                ]),
                _withDirectives(_createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen041.svg" }, null, 512), [
                  [_vShow, !item.isflag]
                ])
              ], 8, _hoisted_7)
            ], 2)
          ]))
        }), 128))
      ])
    ])), [
      [_directive_loading, _ctx.loading]
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-light me-2",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDiscard && _ctx.handleDiscard(...args)))
      }, [
        _createElementVNode("span", _hoisted_9, [
          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr059.svg" })
        ]),
        _createTextVNode(_toDisplayString(_ctx.$t("common.reset")), 1)
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
      }, [
        _createElementVNode("span", _hoisted_10, [
          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr064.svg" })
        ]),
        _createTextVNode(_toDisplayString(_ctx.$t("common.apply")), 1)
      ])
    ])
  ]))
}