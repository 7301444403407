
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiBase, ApiWarehouseInventory } from "@/core/api";
import {
  formatDateTime,
  modalShowListener,
} from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { TaggingItem } from "@/core/directive/interface/common";
import EmptyQuery from "@/components/layout/EmptyQuery.vue";

export default defineComponent({
  name: "warehouse-management-inspection-mismatched-pim-sku-modal",
  props: {
    scanNumber: {
      type: String,
      default: "",
    },
  },
  components: { ProductInfo, SkuInfo, EmptyQuery },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const mismatchedPimSkuRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      items: [] as any[],
      itemsLoading: false,
      brand: "",
      brandOptions: [] as TaggingItem[],
      brandLoading: false,
      search: "",
      radio: "",
    });

    const rules = ref({
      // radio: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const options = ref({});

    // const userInfo = ref<User>(store.getters.currentUser);

    // watch(
    //   () => store.getters.currentUser,
    //   (newVal) => {
    //     userInfo.value = newVal;
    //   }
    // );

    const isDisabledSubmit = computed(() => {
      let flag = false;
      if (formData.value.radio === "") {
        flag = true;
      }
      return flag;
    });

    const getBrandSourceData = async (value, id?) => {
      formData.value.brandLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getBrandSourceData(params);
      formData.value.brandLoading = false;
      if (data.code == 0) {
        formData.value.brandOptions = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const getShowInfo = async () => {
      formData.value.itemsLoading = true;
      const { data } = await ApiWarehouseInventory.getInventoryPimSku({
        brand_id: formData.value.brand,
        value: formData.value.search,
      });
      formData.value.itemsLoading = false;
      if (data.code == 0) {
        formData.value.radio = "";
        formData.value.items = data.data.items;
      } else {
        showServerErrorMsg(data);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getBrandSourceData("")])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      const product = formData.value.radio;
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          emit("update-list", product);
          hideModal(mismatchedPimSkuRef.value);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(mismatchedPimSkuRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    const customFormatDateTime = (date, format = "YYYY-MM-DD HH:mm") => {
      return formatDateTime(date, format);
    };

    onMounted(() => {
      modalShowListener(mismatchedPimSkuRef.value, () => {
        init();
      });
      modalHideListener(mismatchedPimSkuRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formatDateTime,
      formRef,
      mismatchedPimSkuRef,
      props,
      loading,
      formData,
      rules,
      options,
      // userInfo,
      isDisabledSubmit,
      searchBrandSourceItems,
      getShowInfo,
      handleDiscard,
      resetForm,
      submit,
      customFormatDateTime,
    };
  },
});
