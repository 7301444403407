
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";

export default defineComponent({
  components: { SkuInfo, ProductInfo },
  name: "warehouse-management-inspection-exception-product-modal",
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const exceptionProductRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      radio: 0,
      product: [] as any[],
      number: "",
    });

    const rules = ref({
      //   radio: [
      //     {
      //       required: true,
      //       message: t("common.isRequired"),
      //       trigger: "change",
      //     },
      //   ],
    });

    const modalShow = (product: any[], number: string) => {
      showModal(exceptionProductRef.value);
      formData.value.product = product;
      formData.value.number = number;
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      const data = formData.value.product[formData.value.radio],
        number = formData.value.number;
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          // submitRadio();
          emit("update-list", data, number);
          hideModal(exceptionProductRef.value);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(exceptionProductRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    onMounted(() => {
      modalShowListener(exceptionProductRef.value, () => {
        // init();
      });
      modalHideListener(exceptionProductRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formRef,
      exceptionProductRef,
      props,
      loading,
      formData,
      rules,
      modalShow,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
