
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { showModal } from "@/core/directive/function/common";

export default defineComponent({
  name: "warehouse-management-inspection-update-double-sale-article-modal",
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateDoubleSaleArticleRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      items: [] as string[],
    });

    const rules = ref({});

    const options = ref({});

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      //   formRef.value.validate((valid: boolean) => {
      //     if (valid) {
      //       if (loading.value) return;
      //       submitRadio();
      //     } else {
      //       showValidateErrorMsg();
      //       return false;
      //     }
      //   });
    };

    const removeItem = (index) => {
      const value = formData.value.items[index];
      emit("update-list", value);
      formData.value.items.splice(index, 1);
      if (formData.value.items.length === 0) {
        handleDiscard();
      }
    };

    const handleDiscard = () => {
      hideModal(updateDoubleSaleArticleRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value.items = [];
      emit("reset-form");
    };

    const modalShow = () => {
      showModal(updateDoubleSaleArticleRef.value);
    };

    const initData = (guids) => {
      let arr: string[] = [];
      Object.keys(guids).forEach((key) => {
        if (guids[key] === 1) {
          arr.push(key);
        }
      });
      formData.value.items = arr;
      modalShow();
    };

    onMounted(() => {
      modalHideListener(updateDoubleSaleArticleRef.value, () => {
        resetForm();
      });
    });

    return {
      formRef,
      updateDoubleSaleArticleRef,
      props,
      loading,
      formData,
      rules,
      options,
      removeItem,
      initData,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
